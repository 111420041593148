import React from "react";
import logo from "./logo.svg";
import linkedIn from "./linkedin.svg";
import "./App.css";

function App() {
  return (
    <div>
      <div className="container">Momenteel in onderhoud</div>
      <a href="https://www.linkedin.com/in/issam-el-nasiri/">
        <img src={linkedIn} className="icon" alt="logo" />
      </a>
      <p className="text">© 2023 Issam El Nasiri</p>
    </div>
  );
}

export default App;
